import React from 'react'

const Footer = () => {
  return (
    <footer className="py-2">
      <div className="container text-center">
        <hr className='mt-4' style={{opacity: 'unset'}}></hr>
        <div className='row align-items-center pt-2 '>
          <div className='col-lg col-12 text-center'>
            <p className='text-center pt-2'><b>© {new Date().getFullYear()} - Lucas Torino</b></p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
