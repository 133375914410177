import React, { Fragment } from 'react'
import PropTypes from 'prop-types';

/* COMPONENTS */
import Header from '../Header/header'
import Footer from '../../components/Footer/footer'
import '../../font/font.css'

/* MAIN SCSS */
import '../../scss/base.scss'

const Layout = ({ children }) => {
  return (
    <Fragment >
      <Header/>
      <main>
        { children }
      </main>
      <Footer />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
