import { Link } from 'gatsby'
import React from 'react'
import './header.scss'

const Header = () => {

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark py-3">
      <div className='container'>
        <a className="navbar-brand" href="/">
          Lucas Torino
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
          <ul className="navbar-nav justify-content-end">
            <li className="nav-item">
              <Link className="nav-link navhr" aria-current="page" href="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about/">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact/">Contact</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " to="/blog/" tabindex="-1" aria-disabled="true">Blog</Link>
            </li>
          </ul>
        </div>
      </div>

    </nav>
  )
}

export default Header
